var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"hero"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"hero__row"},[_vm._m(0),_c('div',{staticClass:"hero__content"},[_vm._m(1),(!_vm.loading)?_c('div',[(_vm.startDuration > 0)?_c('div',[_c('div',{staticClass:"mb-s"},[_vm._v("Начнется через:")]),_c('StartCount',{attrs:{"duration":_vm.startDuration}}),(_vm.preRegUrl)?_c('div',[_c('div',{staticClass:"mt-l mb-l"},[_vm._v(" А пока приглашаем пройти предварительную регистрацию на платформе «Талант», и мы уведомим вас о старте олимпиады! ")]),_c('BaseButton',{staticClass:"hero__btn hero__btn-reg",attrs:{"tag":"a","size":"lg","theme":"primary","href":_vm.preRegUrl}},[_vm._v("Предварительная регистрация")])],1):_vm._e()],1):(_vm.isAllRegClosed)?_c('div',[(_vm.loginUrl)?_c('BaseButton',{staticClass:"hero__btn",attrs:{"tag":"a","size":"lg","theme":"reg","href":_vm.loginUrl},on:{"click":function($event){return _vm.gtmEvent({
                  eventLabel: 'slider_block',
                  eventAction: 'go_to_enter_account',
                })}}},[_vm._v("войти в учетную запись")]):_vm._e(),_c('div',{staticClass:"mt-l mb-s"},[_vm._v(" Регистрация на текущий сезон олимпиады завершена. ")]),(_vm.preRegUrl)?_c('div',[_c('div',{staticClass:"mb-l"},[_vm._v(" А пока приглашаем пройти "),_c('a',{staticClass:"link",attrs:{"href":_vm.preRegUrl}},[_vm._v("предварительную регистрацию")]),_vm._v(" на платформе «Талант», и мы уведомим вас о старте олимпиады! ")])]):_vm._e()],1):_c('div',{staticClass:"hero__footer"},[(_vm.regUrl)?_c('BaseButton',{staticClass:"hero__btn hero__btn-reg",attrs:{"tag":"a","size":"lg","theme":"primary","href":_vm.regUrl},on:{"click":function($event){return _vm.gtmEvent({
                  eventLabel: 'slider_block',
                  eventAction: 'go_to_registration',
                })}}},[_vm._v("Зарегистрироваться")]):_vm._e(),(_vm.loginUrl)?_c('BaseButton',{staticClass:"hero__btn",attrs:{"tag":"a","size":"lg","theme":"reg","href":_vm.loginUrl},on:{"click":function($event){return _vm.gtmEvent({
                  eventLabel: 'slider_block',
                  eventAction: 'go_to_enter_account',
                })}}},[_vm._v("войти в учетную запись")]):_vm._e()],1)]):_vm._e()])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hero__stage"},[_c('img',{staticClass:"hero__stage-lines",attrs:{"src":require("@/assets/images/landing/hero/hero_bg.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hero__headings"},[_c('h1',{staticClass:"hero__title h1"},[_vm._v(" Национальная технологическая олимпиада ")])])
}]

export { render, staticRenderFns }
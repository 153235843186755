<template>
  <div
    v-if="countDown"
    class="count">
    <div
      v-for="(item, idx) in countDown"
      :key="idx"
      class="count-item">
      <div class="count-item__digit">{{ item.count[0] }}</div>
      <div class="count-item__digit">{{ item.count[1] }}</div>
      <div class="count-item__label">{{ item.title }}</div>
    </div>
  </div>
</template>

<script>
import { numCases } from "@/utils";
import dayjs from "@/plugins/dayjs";

export default {
  name: "StartCount",
  props: {
    duration: {
      type: Number,
    },
  },
  computed: {
    countDown() {
      const { duration } = this;
      if (!duration || duration < 0) return;
      const { days, hours, minutes, seconds, years, months } =
        dayjs.duration(duration).$d;
      const res = [];

      if (years > 0) {
        res.push({
          value: years,
          count: ("0" + years).slice(-2),
          title: numCases(["год", "года", "лет"], years),
        });
      }

      if (months > 0) {
        res.push({
          value: months,
          count: ("0" + months).slice(-2),
          title: numCases(["месяц", "месяца", "месяцев"], months),
        });
      }

      res.push(
        {
          value: days,
          count: ("0" + days).slice(-2),
          title: numCases(["день", "дня", "дней"], days),
        },
        {
          value: hours,
          count: ("0" + hours).slice(-2),
          title: numCases(["час", "часа", "часов"], hours),
        },
        {
          value: minutes,
          count: ("0" + minutes).slice(-2),
          title: numCases(["минуту", "минуты", "минут"], minutes),
        },
        {
          value: seconds,
          count: ("0" + seconds).slice(-2),
          title: numCases(["секунду", "секунды", "секунд"], seconds),
        }
      );

      return res.slice(0, 4);
    },
  },
};
</script>

<style lang="less" scoped>
.count {
  display: flex;
  flex-direction: row;
  font-size: 14px;

  @media (max-width: @screen-xs) {
    font-size: 11px;
  }

  &-item {
    display: flex;
    flex-wrap: wrap;
    width: 7em;
    min-width: 0px;
    justify-content: space-between;
    align-items: stretch;
    margin-right: 0.8em;
    position: relative;

    &::after {
      display: block;
      content: ":";
      position: absolute;
      right: -0.5em;
      top: 1.5em;
      font-weight: bold;
    }

    &:last-child::after {
      display: none;
    }

    &__digit {
      font-size: 2.4em;
      flex: 0 0 48%;
      background: fade(@lp-outline-color, 10%);
      border-radius: 8px;
      text-align: center;
      min-width: 0px;
      border: 1px solid @lp-outline-color;
      color: #fff;
      padding: 0.2em 0.4em;
    }

    &__label {
      flex: 1 0 100%;
      text-align: center;
      padding-top: 5px;
      font-size: 1.2em;
    }
  }
}
</style>

<template>
  <section class="hero">
    <div class="container">
      <div class="hero__row">
        <div class="hero__stage">
          <img
            src="@/assets/images/landing/hero/hero_bg.svg"
            alt=""
            class="hero__stage-lines" />
        </div>
        <div class="hero__content">
          <div class="hero__headings">
            <h1 class="hero__title h1">
              Национальная технологическая олимпиада
            </h1>
          </div>

          <div v-if="!loading">
            <div v-if="startDuration > 0">
              <div class="mb-s">Начнется через:</div>
              <StartCount :duration="startDuration" />

              <div v-if="preRegUrl">
                <div class="mt-l mb-l">
                  А&nbsp;пока приглашаем пройти предварительную регистрацию
                  на&nbsp;платформе &laquo;Талант&raquo;,
                  и&nbsp;мы&nbsp;уведомим вас о&nbsp;старте олимпиады!
                </div>
                <BaseButton
                  tag="a"
                  size="lg"
                  theme="primary"
                  :href="preRegUrl"
                  class="hero__btn hero__btn-reg"
                  >Предварительная регистрация</BaseButton
                >
              </div>
            </div>

            <div v-else-if="isAllRegClosed">
              <BaseButton
                v-if="loginUrl"
                tag="a"
                size="lg"
                theme="reg"
                :href="loginUrl"
                class="hero__btn"
                @click="
                  gtmEvent({
                    eventLabel: 'slider_block',
                    eventAction: 'go_to_enter_account',
                  })
                "
                >войти в учетную запись</BaseButton
              >
              <div class="mt-l mb-s">
                Регистрация на текущий сезон олимпиады завершена.
              </div>
              <div v-if="preRegUrl">
                <div class="mb-l">
                  А&nbsp;пока приглашаем пройти
                  <a
                    :href="preRegUrl"
                    class="link"
                    >предварительную регистрацию</a
                  >
                  на&nbsp;платформе &laquo;Талант&raquo;,
                  и&nbsp;мы&nbsp;уведомим вас о&nbsp;старте олимпиады!
                </div>

                <!-- <BaseButton
                v-if="preRegUrl"
                tag="a"
                size="lg"
                theme="primary"
                :href="preRegUrl"
                class="hero__btn hero__btn-reg"
                >Предварительная регистрация</BaseButton
              > -->
              </div>
            </div>

            <div
              v-else
              class="hero__footer">
              <BaseButton
                v-if="regUrl"
                tag="a"
                size="lg"
                theme="primary"
                :href="regUrl"
                class="hero__btn hero__btn-reg"
                @click="
                  gtmEvent({
                    eventLabel: 'slider_block',
                    eventAction: 'go_to_registration',
                  })
                "
                >Зарегистрироваться</BaseButton
              >

              <BaseButton
                v-if="loginUrl"
                tag="a"
                size="lg"
                theme="reg"
                :href="loginUrl"
                class="hero__btn"
                @click="
                  gtmEvent({
                    eventLabel: 'slider_block',
                    eventAction: 'go_to_enter_account',
                  })
                "
                >войти в учетную запись</BaseButton
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import StartCount from "./StartCount.vue";
export default {
  name: "Hero",
  components: { StartCount },
  props: {
    regUrl: {
      type: String,
      default: "",
    },
    loginUrl: {
      type: String,
      default: "",
    },
    preRegUrl: {
      type: String,
      default: "",
    },
    isAllRegClosed: {
      type: Boolean,
    },
    startDuration: {
      type: Number,
      default: 0,
    },
    loading: {
      type: Boolean,
    },
  },
  methods: {
    gtmEvent(params) {
      window.dataLayer?.push({
        event: "ntoLandingEvent",
        eventCategory: "nto_landing_cta",
        ...params,
      });
    },
  },
};
</script>

<style lang="less" scoped>
@header-height: 172px;
@header-height--mobile: 65px;

.hero {
  display: flex;
  align-items: center;
  min-height: calc(100vh - @header-height);
  overflow: hidden;

  &__row {
    display: flex;
    justify-content: space-between;
  }

  &__content {
    position: relative;
    max-width: 660px;
    z-index: 1;
  }

  &__headings {
    position: relative;
    margin-bottom: 35px;
  }

  &__title {
    color: #1a9dd9;
    margin-bottom: 0;
    text-shadow: 4px 3px 0px #00031d;
  }

  &__subtitle {
    position: absolute;
    right: 20px;
    bottom: 0;
    width: 200px;
    line-height: 24px;
    color: #1a9dd9;
    font-weight: 600;
    letter-spacing: 0.19em;
    text-transform: uppercase;
    padding-left: 20px;
    border-left: 1px solid currentColor;
    margin-bottom: 0;
  }

  &__stage {
    order: 1;
    position: relative;
  }

  &__stage {
    user-select: none;
    pointer-events: none;

    &-lines {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      max-width: none !important;
      transform: translateX(90px);
    }

    &-logo {
      position: relative;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
  }

  &__btn {
    margin-right: 30px;
    flex-shrink: 0;
  }

  &__start {
    position: relative;
    color: #a6c4fe;
    margin-bottom: 0;
    padding-left: 80px;

    &::before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      bottom: 0;
      width: 48px;
      height: 31px;
      margin: auto;
      background-image: url("/assets/images/landing/hero/hero_arrow.svg");
      background-repeat: no-repeat;
    }
  }

  .link {
    color: @principal-blue-light;
  }

  @media (max-width: @screen-lg) {
    background-position: right 10% top 50%;

    &__content {
      max-width: 540px;
      margin-right: 50px;
    }

    &__subtitle {
      right: 0;
    }

    &__stage {
      opacity: 0.4;
    }
  }

  @media (max-width: @screen-md) {
    min-height: calc(100vh - @header-height--mobile);

    &__row {
      display: block;
    }

    &__content {
      margin-top: 50px;
      margin-right: 0;
      max-width: 100%;
    }

    &__headings {
      margin-bottom: 20px;
    }

    &__title {
      margin-bottom: 0.5em;
    }

    &__subtitle {
      width: auto;
      position: static;
      border-left: none;
      padding-left: 0;
      font-size: 15px;
      line-height: 1.3;
    }

    &__footer {
      max-width: 100%;
      display: block;
    }

    &__btn {
      width: 100%;
      max-width: 100%;
      margin-bottom: 23px;
      margin-right: 0;
    }

    &__stage {
      opacity: 0.2;
      &-lines {
        left: 50%;
        right: initial;
        transform: translateX(-50%);
      }
    }
  }

  @media (max-width: @screen-xs) {
    &__content {
      z-index: initial;
    }
    &__start {
      padding-left: 55px;

      &::before {
        width: 33px;
        height: 31px;
        background-position: right center;
      }
    }

    &__btn-reg {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      margin-bottom: 0;
      z-index: 10;
    }
  }
}
</style>

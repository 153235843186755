<template>
  <div class="landing-modal">
    <video
      class="landing-modal__video"
      controls="controls"
      autoplay>
      <source
        src="https://storage.yandexcloud.net/onti-public/media/NTO_start_2023.mp4"
        type="video/mp4" />
    </video>
  </div>
</template>

<script>
export default {
  name: "LandingModal",
};
</script>

<style lang="less" scoped>
.landing-modal {
  &__video {
    width: 100%;
  }
}
</style>
